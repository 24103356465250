import React from 'react';
import { ArrowRight } from 'react-feather';
import { Search } from 'carbon-components-react';
import { connect } from 'react-redux';
import { SimpleBarChart, LineChart } from '@carbon/charts-react';
import { createStructuredSelector } from 'reselect';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import Footer from '../../components/footer/footer.component';
import DonutChart from '../../components/charts/donut/donut.chart';

import {
  selectStudentCount,
  selectEmployeeCount,
  selectStudentGenderChartData,
  selectStudentStatusChartData,
  selectEmployeeGenderChartData,
  selectEmployeeStatusChartData,
} from '../../redux/analytics/analytics.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import '@carbon/charts/styles/styles.scss';
import './custom-chart.styles.scss';

import './analytics.styles.scss';

import {
  lineData,
  lineOptions,
  balanceSheetData,
  balanceSheetOptions,
  trialBalanceData,
  trialBalanceOptions,
  incomeStatementData,
  incomeStatementOptions,
  employeesData,
  employeesOptions,
} from './analytics.data';
import { withRouter } from 'react-router-dom';

class AnalyticsPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      loading: false,
      errorMessage: null,
      empData: employeesData,
      empOptions: employeesOptions,
      empActiveTab: 0,
      studentChartType: 'gender',
      employeeChartType: 'gender',
    };
  }

  componentDidMount() {
    const {
      studentCount,
      employeeCount,
      fetchStudentCount,
      fetchEmployeeCount,
      currentUser: { authToken },
    } = this.props;

    if (
      !studentCount.fetching &&
      !studentCount.data &&
      !studentCount.fetchError
    ) {
      fetchStudentCount(authToken);
    }

    if (
      !employeeCount.fetching &&
      !employeeCount.data &&
      !employeeCount.fetchError
    ) {
      fetchEmployeeCount(authToken);
    }
  }

  componentDidUpdate(prevProps) {}

  render() {
    const {
      studentCount,
      employeeCount,
      fetchStudentCount,
      fetchEmployeeCount,
      studentGenderChartData,
      studentStatusChartData,
      employeeGenderChartData,
      employeeStatusChartData,
      currentUser: { authToken },
    } = this.props;

    return (
      <div>
        <MainHeader />

        <div className="analytics page-container">
          <div>
            <span className="h-24">Analytics</span>
            <span>
              <Mainmenu />
            </span>
          </div>
          <div className="mt-15">
            <Search
              id="search"
              name="search"
              labelText=""
              placeHolderText="Search"
            />
          </div>

          <h3 className="h-18 mt-25">At a glance</h3>

          <div className="bx--row">
            <div className="bx--col-md-4">
              <div className="chart-box hide-legend">
                <h3 className="h-18 text-primary mb-10">
                  Students&nbsp;
                  {studentCount.fetchError && (
                    <span>
                      :&nbsp;
                      <span
                        className="text-danger text-14 pointer"
                        onClick={() => fetchStudentCount(authToken)}>
                        Error occured. Click to try again
                      </span>
                    </span>
                  )}
                </h3>
                <div className="d-flex-space-between">
                  <span className="text-14 text-grey">
                    {studentCount.data ? (
                      <span className="text-grey">
                        Total: {studentCount.data.all}, Enrolled:&nbsp;
                        {studentCount.data.totalEnrolled}, Terminated:&nbsp;
                        {studentCount.data.totalTerminated}, Graduated:&nbsp;
                        {studentCount.data.totalGraduated}
                      </span>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
                <hr className="grey-hr" />
                <div>
                  <div className="donut-container">
                    <DonutChart
                      data={
                        this.state.studentChartType === 'gender'
                          ? studentGenderChartData
                          : studentStatusChartData
                      }
                      loading={studentCount.fetching}
                    />
                  </div>
                </div>

                <div className="bx--row chart-tabs text-center text-grey text-14">
                  <div
                    className={`bx--col-lg-6 pointer chart-tab-item ${
                      this.state.studentChartType === 'gender'
                    }`}
                    onClick={() =>
                      this.setState({
                        studentChartType: 'gender',
                      })
                    }>
                    Gender
                  </div>
                  {/*<div
                    className={`bx--col-lg-4 pointer chart-tab-item ${
                      this.state.stdActiveTab == 1
                    }`}
                    onClick={() =>
                      this.setState({
                        stdActiveTab: 1,
                      })
                    }
                  >
                    Age
                  </div>*/}
                  <div
                    className={`bx--col-lg-6 pointer chart-tab-item ${
                      this.state.studentChartType === 'status'
                    }`}
                    onClick={() =>
                      this.setState({
                        studentChartType: 'status',
                      })
                    }>
                    Status
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-md-4">
              <div className="chart-box hide-legend">
                <h3 className="h-18 text-primary mb-10">
                  Employees&nbsp;
                  {employeeCount.fetchError && (
                    <span>
                      :&nbsp;
                      <span
                        className="text-danger text-14 pointer"
                        onClick={() => fetchEmployeeCount(authToken)}>
                        Error occured. Click to try again
                      </span>
                    </span>
                  )}
                </h3>
                <div className="d-flex-space-between">
                  {employeeCount.data ? (
                    <span className="text-14 text-grey">
                      Total: {employeeCount.data.all}, Active:{' '}
                      {employeeCount.data.totalActive}, Terminated:{' '}
                      {employeeCount.data.totalTerminated}
                    </span>
                  ) : (
                    <span className="text-grey">N/A</span>
                  )}
                </div>
                <hr className="grey-hr" />
                <div hidden={this.state.empActiveTab === 1}>
                  <div className="donut-container">
                    <DonutChart
                      data={
                        this.state.employeeChartType === 'gender'
                          ? employeeGenderChartData
                          : employeeStatusChartData
                      }
                      loading={employeeCount.fetching}
                    />
                  </div>
                </div>
                {/*<div hidden={this.state.empActiveTab != 1}>
                  <SimpleBarChart data={empAgeData} options={empAgeOptions} />
                  </div>*/}
                <div className="bx--row chart-tabs text-center text-grey text-14">
                  <div
                    className={`bx--col-lg-6 pointer chart-tab-item ${
                      this.state.employeeChartType === 'gender'
                    }`}
                    onClick={() =>
                      this.setState({
                        employeeChartType: 'gender',
                      })
                    }>
                    Gender
                  </div>
                  {/*<div
                    className={`bx--col-lg-6 pointer chart-tab-item ${
                      this.state.empActiveTab === 1
                    }`}
                    onClick={() =>
                      this.setState({
                        empActiveTab: 1,
                      })
                    }
                  >
                    Age
                  </div>*/}
                  <div
                    className={`bx--col-lg-6 pointer chart-tab-item ${
                      this.state.employeeChartType === 'status'
                    }`}
                    onClick={() =>
                      this.setState({
                        employeeChartType: 'status',
                      })
                    }>
                    Status
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3 className="h-18 mt-30">Run Reports</h3>
          <div className="bx--row mt-10">
            <div className="bx--col-lg-4">
              <div className="chart-box">
                <h3 className="h-18 text-primary mb-10">Balance Sheet</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart
                  data={balanceSheetData}
                  options={balanceSheetOptions}
                />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/balance-sheet`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">Income Statement</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart
                  data={incomeStatementData}
                  options={incomeStatementOptions}
                />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/income-statement`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box hide-legend">
                <h3 className="h-18 text-primary mb-10">Trial Balance</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <SimpleBarChart
                  data={trialBalanceData}
                  options={trialBalanceOptions}
                />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/trial-balance`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">Cash Received</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/cash-received`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">Expenses</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/cash-spent`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">Journal Voucher</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/transactions-report`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">Budget</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart
                  data={incomeStatementData}
                  options={incomeStatementOptions}
                />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/budget-report`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">Student Statement</h3>
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`analytics/student-statement`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">
                  Ledger Account Report
                </h3>
                {/*<div className="text-14 text-grey-light">
                <Sort />
                <span> Aug 24, 2019 - Jan 31, 2020</span>
                </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                  d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(
                        `analytics/ledger-account-report`,
                      );
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">Debtors List</h3>
                {/*<div className="text-14 text-grey-light">
                  <Sort />
                  <span> Aug 24, 2019 - Jan 31, 2020</span>
                  </div>*/}
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(`/analytics/debtors-list`);
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">
                  Billed Students Report
                </h3>
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(
                        '/analytics/billed-students-report',
                      );
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">
                  Unbilled Students Report
                </h3>
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(
                        `/analytics/unbilled-students-report`,
                      );
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">
                  Students Attendance Report
                </h3>
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(
                        '/analytics/students-attendance-report',
                      );
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bx--col-lg-4">
              <div className="chart-box ">
                <h3 className="h-18 text-primary mb-10">
                  Students Enrollment Report
                </h3>
                <hr className="grey-hr mb-15 mt-10" />
                <LineChart data={lineData} options={lineOptions} />
                <hr className="grey-hr" />
                <div className="mt-15 d-flex flex-end">
                  <div
                    className="text-12 text-primary font-bold 
                    d-flex align-items-center pointer"
                    onClick={() => {
                      this.props.history.push(
                        `/analytics/students-enrollment-report`,
                      );
                    }}>
                    <span>RUN REPORTS </span> &nbsp;&nbsp;
                    <span>
                      {' '}
                      <ArrowRight size="14" strokeWidth="3" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  studentCount: selectStudentCount,
  studentGenderChartData: selectStudentGenderChartData,
  studentStatusChartData: selectStudentStatusChartData,
  employeeGenderChartData: selectEmployeeGenderChartData,
  employeeStatusChartData: selectEmployeeStatusChartData,
  employeeCount: selectEmployeeCount,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStudentCount: (authToken) =>
    dispatch({
      type: 'FETCH_STUDENT_COUNT_REQUESTED',
      payload: { authToken },
    }),
  fetchEmployeeCount: (authToken) =>
    dispatch({
      type: 'FETCH_EMPLOYEE_COUNT_REQUESTED',
      payload: { authToken },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage),
);
