import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Printer } from 'react-feather';

import MainHeader from '../../../components/main-header/main-header.component';
import Mainmenu from '../../../components/menu/main-menu/main-menu.component';

import Footer from '../../../components/footer/footer.component';

import {
  Select,
  SelectItem,
  DatePicker,
  DatePickerInput,
  TextInput,
  Pagination,
} from 'carbon-components-react';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import {
  selectClasses,
  selectClassLabels,
} from '../../../redux/selectors/class.selectors';
import {
  setClasses,
  setClassLabels,
} from '../../../redux/actions/class.actions';

import { getClasses, getClassLabels } from '../../../api/class.service';

import { connect, useSelector } from 'react-redux';
import { formatDate, getQueryString } from '../../../utils/common.util';
import LoaderPane from '../../../components/loader-pane/loader-pane.component';

import './students-attendance-report.styles.scss';
import useSWR from 'swr';
import CustomButton from '../../../components/custom-button/custom-btn.component';
import TemplateModal from '../../../components/modal/template/template.component';
import {
  selectAcademicYears,
  selectTerms,
} from '../../../redux/selectors/academic-year.selectors';
import { selectEnrolledStudents } from '../../../redux/selectors/student.selectors';
import fileDownload from 'js-file-download';
import { fetchService } from '../../../api/common';

const StudentsAttendanceReportPage = (props) => {
  const { authToken } = useSelector(selectCurrentUser);
  const academicYears = useSelector(selectAcademicYears);
  const terms = useSelector(selectTerms);

  const [state, setState] = useState({
    classes: props.classes,
    classLabels: props.classLabels,
    openPrintModal: false,
    academicYearId: '',
    termId: '',
    selectedStudent: '',
    studentSearch: '',
    exporting: '',
    exportingError: '',
  });

  useEffect(() => {
    if (props.classes.length === 0) {
      getClasses(props.currentUser.authToken).then((response) => {
        if (response.success) {
          props.setClasses(response.classes);
        }
      });
    }

    if (props.classLabels.length === 0) {
      getClassLabels(props.currentUser.authToken).then((response) => {
        if (response.success) {
          props.setClassLabels(response.classLabels);
        }
      });
    }
  }, []);

  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 20,
  });
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [classId, setClassId] = useState();
  const [classLabelId, setClassLabelId] = useState();
  const [status, setStatus] = useState('');

  const enrolledStudents = useSelector(selectEnrolledStudents);
  const filter = state.studentSearch.toLowerCase();
  const filteredStudents = enrolledStudents.filter(
    (student) =>
      student.classId == classId &&
      (student.firstName.toLowerCase().includes(filter) ||
        student.lastName.toLowerCase().includes(filter) ||
        student.studentId.toLowerCase().includes(filter)),
  );

  const [isReady, setIsReady] = useState(false);

  const buildUrl = (isExporting = false) => {
    return `students-attendance/attendance-sheet${isExporting ? '/export' : ''}?${getQueryString(
      {
        fromDate,
        toDate,
        classId,
        classLabelId,
        attendanceStatus: status,
        studentId: state.selectedStudent?.id,
        termId: state.termId,
        academicYearId: state.academicYearId,
        take: paginate.pageSize,
        skip: (paginate.page - 1) * paginate.pageSize,
      },
    )}`;
  };
  const { data, error, mutate } = useSWR(isReady && buildUrl());

  const exportReport = async () => {
    setState({ ...state, exporting: true, exportingError: null });

    const response = await fetchService(buildUrl(true), authToken);

    if (response.success) {
      setState({ ...state, exporting: false });
      fileDownload(
        response.data.data,
        `students-attendance-report${new Date()}.xlsx`,
      );

      return;
    }

    setState({
      ...state,
      exporting: false,
      exportingError: response.message || 'Exporting failed',
    });
  };

  const filterTexts = [
    {
      label: 'Academic Year',
      text:
        academicYears?.find((ac) => ac.id == state.academicYearId)
          ?.description || 'All',
    },
    {
      label: 'Term',
      text: terms?.find((t) => t.id == state.term)?.description || 'All',
    },
    {
      label: 'Class',
      text: `${state?.classes?.find((c) => c.id == classId)?.description || 'All'}${state?.classLabels.find((cl) => cl.id == classLabelId) ? ` ${state?.classLabels.find((cl) => cl.id == classLabelId)?.description}` : ''}`,
    },
    {
      label: 'Student',
      text: state.selectedStudent
        ? `${state.selectedStudent?.firstName} ${state.selectedStudent?.lastName}`
        : 'All',
    },
    {
      label: 'Status',
      text: status || 'All',
    },
  ];

  const title = `Students Attendance Report${fromDate && toDate ? `: ${new Date(fromDate).toDateString()} - ${new Date(toDate).toDateString()}` : ''}`;

  return (
    <div>
      <MainHeader />

      {state.openPrintModal && (
        <TemplateModal
          title={title}
          school={props.currentUser.school}
          topContentData={filterTexts}
          content={<TableContent data={data} />}
          open={true}
          closeAction={() => setState({ ...state, openPrintModal: false })}
        />
      )}

      <div className="students-attendance page-container">
        <div>
          <p>
            <span className="text-12 font-bold text-grey">You are here</span>
            &nbsp;&nbsp;
            <Link to="/analytics" className="text-12 text-dark">
              Analytics
            </Link>
            &nbsp;<span className="text-12">&gt;</span>&nbsp;
            <span className="text-12 text-primary font-bold">
              Students Attendance Report
            </span>
          </p>
        </div>
        <div>
          <span className="right">
            <Mainmenu />
          </span>
        </div>

        <hr className="grey-hr mt-20" />

        <div className="h-24 uppercase mt-15">{title}</div>

        {/* filters */}
        <div className="mt-20 bx--row">
          {/* academic year */}
          <div className="bx--col-md-2 npr mb-20">
            <Select
              labelText="Academic Year"
              onChange={(e) =>
                setState({
                  ...state,
                  academicYearId: e.target.value,
                  term: '',
                })
              }>
              <SelectItem
                disabled
                selected
                value=""
                text="Select Academic Year"
              />
              <SelectItem value="" text="All" />
              {academicYears.map((year) => (
                <SelectItem
                  value={year.id}
                  text={year.description}
                  selected={state?.academicYearId === year.id}
                />
              ))}
            </Select>
          </div>
          {/* terms */}
          <div className="bx--col-md-2 npr mb-20">
            <Select
              disabled={!state.academicYearId}
              labelText="Term"
              onChange={(e) => setState({ ...state, termId: e.target.value })}>
              <SelectItem disabled selected value="" text="Select Term" />
              <SelectItem value="" text="All" />
              {terms
                .filter((m) => m.academicYearId == state.academicYearId)
                .map((term) => (
                  <SelectItem
                    value={term.id}
                    text={term.description}
                    selected={state.termId === term.id}
                  />
                ))}
            </Select>
          </div>
          {/* class */}
          <div className="bx--col-md-2 npr mb-20">
            <Select
              labelText="Class"
              onChange={(e) => setClassId(e.target.value)}>
              <SelectItem selected disabled value="" text="Class" />
              <SelectItem value="" text="All " />
              {props.classes?.map((item) => (
                <SelectItem text={item.description} value={item.id} />
              ))}
            </Select>
          </div>
          {/* class label  */}
          <div className="bx--col-md-2 npr mb-20">
            <Select
              disabled={!classId}
              labelText="Class label"
              onChange={(e) => setClassLabelId(e.target.value)}>
              <SelectItem selected value="" text="Class label " />
              {props.classLabels?.map((item) => (
                <SelectItem text={item.description} value={item.id} />
              ))}
            </Select>
          </div>
          {/* student */}
          <div className="bx--col-md-2 npr mb-20">
            <TextInput
              labelText="Student"
              autoComplete="off"
              value={
                state.selectedStudent
                  ? `${state.selectedStudent.firstName} ${state.selectedStudent.lastName} [${state.selectedStudent.studentId}]`
                  : state.studentSearch
              }
              onChange={(e) => {
                setState({
                  ...state,
                  studentSearch: e.target.value,
                  selectedStudent: null,
                });
              }}
              disabled={!classId}
              placeholder="Search student"
            />
            {state.studentSearch.length > 0 && !state.selectedStudent && (
              <div className="student-result-box fade-in">
                {filteredStudents.map((student) => (
                  <div
                    className="student-item"
                    onClick={() =>
                      setState({
                        ...state,
                        selectedStudent: student,
                      })
                    }>
                    <p className="name">{`${student.firstName} ${student.lastName}`}</p>
                    <span className="text-grey text-14">
                      {student.studentId}
                    </span>

                    <hr className="grey-hr" />
                  </div>
                ))}

                {filteredStudents.length === 0 && (
                  <p>No student found with the name {state.studentSearch}</p>
                )}
              </div>
            )}
          </div>
          {/* start date */}
          <div className="bx--col-md-2 npr">
            <DatePicker
              dateFormat="Y-m-d"
              datePickerType="single"
              id="start-date-picker"
              onChange={(value) => {
                if (value.length > 0) {
                  setFromDate(
                    [
                      value[0].getFullYear(),
                      ('0' + (value[0].getMonth() + 1)).slice(-2),
                      ('0' + value[0].getDate()).slice(-2),
                    ].join('-'),
                  );
                } else setFromDate('');
              }}
              onClose={(value) => {}}>
              <DatePickerInput
                className="start-date"
                id="start-date"
                name="startDate"
                labelText="Start date"
                value={fromDate}
                pattern="d{1,2}/d{4}"
                placeholder="YYYY-MM-DD"
                type="text"
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
              />
            </DatePicker>
          </div>
          {/* end date */}
          <div className="bx--col-md-2 npr">
            <DatePicker
              dateFormat="Y-m-d"
              datePickerType="single"
              id="end-date-picker"
              onChange={(value) => {
                if (value.length > 0) {
                  setToDate(
                    [
                      value[0].getFullYear(),
                      ('0' + (value[0].getMonth() + 1)).slice(-2),
                      ('0' + value[0].getDate()).slice(-2),
                    ].join('-'),
                  );
                } else setToDate('');
              }}
              onClose={(value) => {}}>
              <DatePickerInput
                className="end-date"
                id="end-date"
                name="endDate"
                labelText="End date"
                value={toDate}
                pattern="d{1,2}/d{4}"
                placeholder="YYYY-MM-DD"
                type="text"
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
              />
            </DatePicker>
          </div>
          {/* status */}
          <div className="bx--col-md-2 npr  mb-20">
            <Select
              labelText="Status"
              onChange={(e) => setStatus(e.target.value)}>
              <SelectItem selected value="" text="All" />
              <SelectItem text={'Present'} value={'Present'} />
              <SelectItem text={'Absent'} value={'Absent'} />
              <SelectItem value="Late" text="Late" />
              <SelectItem value="Excused" text="Excused" />
            </Select>
          </div>

          {/* run report button  */}
          <div className="bx--col-md-2 npr">
            <CustomButton
              classes="f-width primary mt-25 font-bold"
              disabled={!classId}
              onClick={() => setIsReady(true)}>
              RUN REPORT
            </CustomButton>
          </div>
        </div>

        <LoaderPane
          loading={!data && !error && isReady}
          error={error?.message}
          noRecords={data?.data?.length === 0}
          onReload={() => mutate()}
        />

        {!classId && (
          <div className="text-center font-500 text-primary text-danger text-20 mt-10">
            You must select at least a class to generate report.
          </div>
        )}

        {data?.data?.length > 0 && (
          <div className="mt-20">
            <TableContent data={data} />
            <Pagination
              backwardText="Previous page"
              forwardText="Next page"
              page={paginate.page}
              pageNumberText="Page Number"
              pageSize={paginate.pageSize}
              pageSizes={[10, 20, 30, 40, 50, 100]}
              totalItems={data?.pagingInfo?.recordsCount}
              onChange={(value) => setPaginate(value)}
            />
          </div>
        )}

        {data?.data?.length > 0 && (
          <div className="mt-35">
            {state.exportingError && (
              <div className="text-danger mb-10">{state.exportingError}</div>
            )}
            <div className="bx--row mt-10">
              <div className="bx--col-lg-3">
                <CustomButton
                  classes="secondary f-width font-bold"
                  disabled={state.exporting}
                  onClick={exportReport}>
                  {state?.exporting ? (
                    'EXPORTING...'
                  ) : (
                    <span>
                      <Printer size="11" strokeWidth="5px" />
                      &nbsp;EXPORT TO EXCEL
                    </span>
                  )}
                </CustomButton>
              </div>
              <div className="bx--col-lg-2">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => setState({ ...state, openPrintModal: true })}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          </div>
        )}

        <Footer fixed={false} showLinks={false} />
      </div>
    </div>
  );
};

const TableContent = ({ data }) => (
  <div className="table-container">
    <table className="bx--data-table table-white">
      <thead>
        <tr>
          <th>Date</th>
          <th>Unique Id</th>
          <th>Name</th>
          <th>Status</th>
          <th>Class</th>
        </tr>
      </thead>
      <tbody>
        {data?.data.map((attendance, i) => (
          <tr key={i}>
            <td>{formatDate(attendance.date)}</td>
            <td>{attendance.studentNumber}</td>
            <td>{attendance?.name}</td>
            <td className="font-bold">
              <span
                className={`${
                  attendance.status === 'Present'
                    ? 'text-success'
                    : 'text-danger'
                }`}>
                {attendance.status}
              </span>
            </td>
            <td>
              {attendance?.className} {attendance?.classLabel}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  classes: selectClasses,
  classLabels: selectClassLabels,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setClasses: (classes) => dispatch(setClasses(classes)),
  setClassLabels: (classLabels) => dispatch(setClassLabels(classLabels)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StudentsAttendanceReportPage),
);
